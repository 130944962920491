// extracted by css-extract-rspack-plugin
export default {"table":"src-modules-common-table-module__table-CpQGfk","fileTable":"src-modules-common-table-module__fileTable-Xj_y3u"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"table\":\"src-modules-common-table-module__table-CpQGfk\",\"fileTable\":\"src-modules-common-table-module__fileTable-Xj_y3u\"}";
        // 1737641322097
        var cssReload = require("../../../node_modules/@rspack/core/dist/cssExtractHmr.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  